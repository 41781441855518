import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import { PathContentSelectionModal_PathContentMembershipFragmentDoc } from '../../pathContentSelectionModal/index.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReassignmentSelectPathContent_LibraryItemFragment = { __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, publishedVersion?: { __typename?: 'PathVersion', pathContentMemberships: Array<{ __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, skill?: { __typename?: 'Skill', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, trainingResource?: { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null }> } | null } | null };

export type ReassignmentSelectPathContent_LibraryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type ReassignmentSelectPathContent_LibraryItemQuery = { __typename?: 'Query', LibraryItem?: { __typename?: 'LibraryItem', id: string, path?: { __typename?: 'Path', id: number, publishedVersion?: { __typename?: 'PathVersion', pathContentMemberships: Array<{ __typename?: 'PathContentMembership', id: number, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, skill?: { __typename?: 'Skill', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, trainingResource?: { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null, sectionBreak?: { __typename?: 'SectionBreak', id: number, title: { __typename?: 'TranslationSet', en: string } } | null }> } | null } | null } | null };

export const ReassignmentSelectPathContent_LibraryItemFragmentDoc = gql`
    fragment ReassignmentSelectPathContent_LibraryItem on LibraryItem {
  id
  path {
    id
    publishedVersion {
      pathContentMemberships {
        ...PathContentSelectionModal_PathContentMembership
      }
    }
  }
}
    ${PathContentSelectionModal_PathContentMembershipFragmentDoc}`;
export const ReassignmentSelectPathContent_LibraryItemDocument = gql`
    query ReassignmentSelectPathContent_LibraryItem($id: UUID!) {
  LibraryItem(id: $id) {
    ...ReassignmentSelectPathContent_LibraryItem
  }
}
    ${ReassignmentSelectPathContent_LibraryItemFragmentDoc}`;

/**
 * __useReassignmentSelectPathContent_LibraryItemQuery__
 *
 * To run a query within a React component, call `useReassignmentSelectPathContent_LibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useReassignmentSelectPathContent_LibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReassignmentSelectPathContent_LibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReassignmentSelectPathContent_LibraryItemQuery(baseOptions: Apollo.QueryHookOptions<ReassignmentSelectPathContent_LibraryItemQuery, ReassignmentSelectPathContent_LibraryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReassignmentSelectPathContent_LibraryItemQuery, ReassignmentSelectPathContent_LibraryItemQueryVariables>(ReassignmentSelectPathContent_LibraryItemDocument, options);
      }
export function useReassignmentSelectPathContent_LibraryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReassignmentSelectPathContent_LibraryItemQuery, ReassignmentSelectPathContent_LibraryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReassignmentSelectPathContent_LibraryItemQuery, ReassignmentSelectPathContent_LibraryItemQueryVariables>(ReassignmentSelectPathContent_LibraryItemDocument, options);
        }
export type ReassignmentSelectPathContent_LibraryItemQueryHookResult = ReturnType<typeof useReassignmentSelectPathContent_LibraryItemQuery>;
export type ReassignmentSelectPathContent_LibraryItemLazyQueryHookResult = ReturnType<typeof useReassignmentSelectPathContent_LibraryItemLazyQuery>;
export type ReassignmentSelectPathContent_LibraryItemQueryResult = Apollo.QueryResult<ReassignmentSelectPathContent_LibraryItemQuery, ReassignmentSelectPathContent_LibraryItemQueryVariables>;