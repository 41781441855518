import { FC, useMemo, useState } from "react";
import { gql } from "@apollo/client";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import { Button } from "@ui/button";
import { useModal } from "@src/hooks/useModal";
import {
  ModalFooter,
  ModalHeader,
} from "@src/deprecatedDesignSystem/components/ModalComposition";
import { transformToGroupedMemberships } from "./utils";
import UnselectedContentLeftCol from "./components/UnselectedContentLeftCol";
import SelectedContentRightCol from "./components/SelectedContentRightCol";
import { usePathContentSelectionModal_LibraryItemQuery } from "./index.generated";

interface Props {
  pathLibraryItemId: string;
  initialSelectedContentLibraryItemIds: string[];
  onSubmit: (selectedPathContentLibraryItemIds: string[]) => void;
}

const PathContentSelectionModal: FC<Props> = ({
  pathLibraryItemId,
  initialSelectedContentLibraryItemIds,
  onSubmit,
}) => {
  const { closeModal } = useModal();
  const [selectedIds, setSelectedIds] = useState<Set<string>>(
    new Set(initialSelectedContentLibraryItemIds),
  );

  const { data } = usePathContentSelectionModal_LibraryItemQuery({
    variables: { id: pathLibraryItemId },
  });

  const sections = useMemo(
    () => transformToGroupedMemberships(data?.LibraryItem),
    [data],
  );

  const selectedSections = useMemo(() => {
    return sections
      .map((section) => ({
        ...section,
        memberships: section.memberships.filter((membership) => {
          const libraryItem =
            membership.course?.libraryItem ||
            membership.skill?.libraryItem ||
            membership.trainingResource?.libraryItem;
          if (!libraryItem) return false;
          return selectedIds.has(libraryItem.id);
        }),
      }))
      .filter((section) => section.memberships.length > 0);
  }, [sections, selectedIds]);

  const unselectedSections = useMemo(() => {
    return sections
      .map((section) => ({
        ...section,
        memberships: section.memberships.filter((membership) => {
          const libraryItem =
            membership.course?.libraryItem ||
            membership.skill?.libraryItem ||
            membership.trainingResource?.libraryItem;
          if (!libraryItem) return false;
          return !selectedIds.has(libraryItem.id);
        }),
      }))
      .filter((section) => section.memberships.length > 0);
  }, [sections, selectedIds]);

  return (
    <Modal width={878}>
      <ModalHeader>
        <div className="flex flex-1 items-center justify-center gap-2 self-stretch">
          <Text type="P2" fontWeight="SemiBold">
            Select content to reassign
          </Text>
        </div>
      </ModalHeader>
      <div className="flex h-[448px] flex-row self-start">
        <UnselectedContentLeftCol
          unselectedSections={unselectedSections}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
        <SelectedContentRightCol
          selectedSections={selectedSections}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      </div>
      <ModalFooter className="justify-end gap-[12px] p-[12px]">
        <Button variant="outline" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          variant="default"
          onClick={() => {
            onSubmit(Array.from(selectedIds));
            closeModal();
          }}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PathContentSelectionModal;

// GraphQL queries and fragments
gql`
  fragment PathContentSelectionModal_LibraryItem2 on LibraryItem {
    id
    name {
      en
    }
    type
    coverImage {
      ...CoverImage
    }
  }
  fragment PathContentSelectionModal_PathContentMembership on PathContentMembership {
    id
    course {
      id
      libraryItem {
        ...PathContentSelectionModal_LibraryItem2
      }
    }
    skill {
      id
      libraryItem {
        ...PathContentSelectionModal_LibraryItem2
      }
    }
    trainingResource {
      id
      libraryItem {
        ...PathContentSelectionModal_LibraryItem2
      }
    }
    sectionBreak {
      id
      title {
        en
      }
    }
  }

  fragment PathContentSelectionModal_LibraryItem on LibraryItem {
    id
    path {
      id
      publishedVersion {
        pathContentMemberships {
          ...PathContentSelectionModal_PathContentMembership
        }
      }
    }
  }

  query PathContentSelectionModal_LibraryItem($id: UUID!) {
    LibraryItem(id: $id) {
      ...PathContentSelectionModal_LibraryItem
    }
  }
`;
