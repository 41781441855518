import ContentTypeFilter from "@src/components/locations/location-detail/ContentTypeFilter";
import { RoleAutomationFragment } from "@src/components/roles/role-detail/RoleDetail.generated";
import {
  filterAutomationsByContentType,
  filterEnabledAutomations,
  getAutomationContentId,
  getAutomationContentName,
  getAutomationContentType,
  getAutomationContentUrl,
} from "@src/components/users/lib/utils";
import AvatarTitleSubtitle from "@src/deprecatedDesignSystem/components/AvatarTitleSubtitle";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import useActionPermissions from "@hooks/useActionPermissions";
import { getAutomationString } from "@utils/assignments";
import { FC, useState } from "react";
import useShowAssignLibraryItemModal from "@src/components/contentLibrary/assignmentModals/assignLibraryItemModal/hooks/useShowAssignLibraryItemModal";

export enum ContentFilterType {
  ALL = "All",
  COURSE = "Course",
  MODULE = "Module",
  FILE = "File",
}

type Props = {
  automations: RoleAutomationFragment[];
  loading: boolean;
};

const RoleAutomationsTable: FC<Props> = ({ automations, loading }) => {
  const { userActionPermissions } = useActionPermissions();
  const [contentType, setSelectedContentType] = useState<ContentFilterType>(
    ContentFilterType.ALL,
  );
  const enabledAutomations = filterEnabledAutomations(automations);
  const showAssignLibraryItemModal = useShowAssignLibraryItemModal();
  return (
    <Table
      title={"Automations"}
      allItems={enabledAutomations}
      itemId={(item) => item.id}
      filterItems={(items) =>
        filterAutomationsByContentType(items, contentType)
      }
      allColumns={["name", "rule"]}
      columnTitles={{
        name: "Name",
        rule: "Automation rule",
      }}
      responsiveness={{
        name: {
          minWidth: 240,
          flex: 4,
        },
        rule: {
          flex: 4,
          collapseAtScreenWidth: 1200,
        },
      }}
      noDataEmptyState={"No automations match your search"}
      rowClickLink={(x) => getAutomationContentUrl(x)}
      loading={loading}
      sortingFns={{
        name: (x) => getAutomationContentName(x),
        rule: (x) => getAutomationString(x.userSelection),
      }}
      defaultSort={{
        column: "name",
        descending: true,
      }}
      fuseSearchConfig={{
        threshold: 0.2,
        keys: [
          {
            name: "name",
            getFn: (x) => getAutomationContentName(x) || "",
          },
        ],
      }}
      overflowMenu={(x: RoleAutomationFragment) => {
        if (!userActionPermissions?.assignContent) {
          return null;
        }
        return (
          <>
            <ContextMenuItem
              label={"Assign to people"}
              icon={"arrow-up-right"}
              onSelect={() => {
                if (x.libraryItem?.course) {
                  showAssignLibraryItemModal({
                    libraryItemId: x.libraryItem.id,
                  });
                } else if (
                  x.libraryItem?.orgPremiumContentConfig &&
                  x.libraryItem?.orgPremiumContentConfig.premiumContentConfig
                    .catalogPath
                ) {
                  showAssignLibraryItemModal({
                    libraryItemId:
                      x.libraryItem?.orgPremiumContentConfig
                        .premiumContentConfig.catalogPath.path.libraryItem.id,
                    creditCostPerAssignment:
                      x.libraryItem?.orgPremiumContentConfig?.creditCost,
                  });
                } else if (
                  x.libraryItem?.path ||
                  x.libraryItem?.trainingResource
                ) {
                  showAssignLibraryItemModal({
                    libraryItemId: x.libraryItem.id,
                  });
                }
              }}
            />
          </>
        );
      }}
      rightAction={
        <ContentTypeFilter
          key={"contentTypeFilter"}
          selectedContentType={contentType}
          setSelectedContentType={setSelectedContentType}
        />
      }
      rowHeight={50}
      renderFns={{
        name: (x) => {
          return (
            <AvatarTitleSubtitle
              title={getAutomationContentName(x)}
              titleType={"P2"}
              avatarType={"Content"}
              contentId={getAutomationContentId(x)}
              size={"40px"}
              contentType={getAutomationContentType(x)}
              subtitle={{
                subtitle1: getAutomationContentType(x),
              }}
            />
          );
        },
        rule: (x) => getAutomationString(x.userSelection),
      }}
    />
  );
};

export default RoleAutomationsTable;
