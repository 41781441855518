import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseAssignmentSummaryData_AssignmentsQueryVariables = Types.Exact<{
  input: Types.AssignmentsInput;
}>;


export type UseAssignmentSummaryData_AssignmentsQuery = { __typename?: 'Query', Assignments: { __typename?: 'Assignments', objects: Array<{ __typename?: 'Assignment', id: string, status: AssignmentStatus, user: { __typename?: 'Employee', id: number } }> } };


export const UseAssignmentSummaryData_AssignmentsDocument = gql`
    query useAssignmentSummaryData_Assignments($input: AssignmentsInput!) {
  Assignments(input: $input) {
    objects {
      id
      status
      user {
        id
      }
    }
  }
}
    `;

/**
 * __useUseAssignmentSummaryData_AssignmentsQuery__
 *
 * To run a query within a React component, call `useUseAssignmentSummaryData_AssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseAssignmentSummaryData_AssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseAssignmentSummaryData_AssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUseAssignmentSummaryData_AssignmentsQuery(baseOptions: Apollo.QueryHookOptions<UseAssignmentSummaryData_AssignmentsQuery, UseAssignmentSummaryData_AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseAssignmentSummaryData_AssignmentsQuery, UseAssignmentSummaryData_AssignmentsQueryVariables>(UseAssignmentSummaryData_AssignmentsDocument, options);
      }
export function useUseAssignmentSummaryData_AssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseAssignmentSummaryData_AssignmentsQuery, UseAssignmentSummaryData_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseAssignmentSummaryData_AssignmentsQuery, UseAssignmentSummaryData_AssignmentsQueryVariables>(UseAssignmentSummaryData_AssignmentsDocument, options);
        }
export type UseAssignmentSummaryData_AssignmentsQueryHookResult = ReturnType<typeof useUseAssignmentSummaryData_AssignmentsQuery>;
export type UseAssignmentSummaryData_AssignmentsLazyQueryHookResult = ReturnType<typeof useUseAssignmentSummaryData_AssignmentsLazyQuery>;
export type UseAssignmentSummaryData_AssignmentsQueryResult = Apollo.QueryResult<UseAssignmentSummaryData_AssignmentsQuery, UseAssignmentSummaryData_AssignmentsQueryVariables>;