import { FC } from "react";
import { X } from "lucide-react";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { cn } from "@src/ui/lib/utils";
import { PathContentSelectionModal_PathContentMembershipFragment } from "../index.generated";
import Text from "@ui/text";
import { LibraryItemType } from "@src/types.generated";

interface Props {
  memberships: PathContentSelectionModal_PathContentMembershipFragment[];
  selectedIds: Set<string>;
  setSelectedIds?: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const MembershipContentCards: FC<Props> = ({
  memberships,
  selectedIds,
  setSelectedIds,
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {memberships.map((membership) => {
        const libraryItem =
          membership?.course?.libraryItem ||
          membership?.trainingResource?.libraryItem ||
          membership?.skill?.libraryItem;
        if (!libraryItem) return null;
        const isContentSelected = selectedIds.has(libraryItem.id);
        if (!isContentSelected) return null;
        const handleClick = () => {
          if (!setSelectedIds) return;
          const newSelected = new Set(selectedIds);
          if (isContentSelected) {
            newSelected.delete(libraryItem.id);
          } else {
            newSelected.add(libraryItem.id);
          }
          setSelectedIds(newSelected);
        };

        return (
          <div
            key={membership.id}
            className={cn(
              "flex items-center justify-between rounded-md p-[8px] cursor-pointer transition-colors",
              "bg-background shadow-[0px_0px_0px_0.5px_rgba(0,0,0,0.06),0px_0px_6px_0px_rgba(0,0,0,0.02),0px_2px_4px_0px_rgba(0,0,0,0.04)]",
            )}
            onClick={handleClick}
          >
            <div className="flex flex-1 items-center gap-2">
              <ContentAvatar
                size="32px"
                coverImage={libraryItem.coverImage}
                contentType="Course"
                defaultBackgroundHashKey={libraryItem.id}
                style={{ borderRadius: 4 }}
              />
              <div className="flex h-[32px] max-h-[32px] flex-1 flex-col justify-center">
                <Text
                  type="P2"
                  className="self-stretch"
                  fontWeight="Medium"
                  ellipsis={false}
                  multiline={true}
                >
                  {libraryItem.name.en}
                </Text>
                <Text type="P2" className="mt-[-2px] text-gray-7">
                  {ContentTypeCopy[libraryItem.type]}
                </Text>
              </div>
            </div>
            {setSelectedIds && <X className="mx-1 size-4 text-gray-7" />}
          </div>
        );
      })}
    </div>
  );
};

export const ContentTypeCopy: Partial<Record<LibraryItemType, string>> = {
  [LibraryItemType.Course]: "Course",
  [LibraryItemType.Path]: "Module",
  [LibraryItemType.Skill]: "Check-in",
  [LibraryItemType.TrainingResource]: "Resource",
};

export default MembershipContentCards;
