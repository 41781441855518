import { FC } from "react";
import Text from "@ui/text";
import { Button } from "@ui/button";
import { Plus, X } from "lucide-react";
import ScrollableFlexGrow from "@src/components/ui/flexBox/ScrollableFlexGrow";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { cn } from "@src/ui/lib/utils";
import { MembershipSection } from "../types";
import { ContentTypeCopy } from "./MembershipContentCards";

interface Props {
  unselectedSections: MembershipSection[];
  selectedIds: Set<string>;
  setSelectedIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const UnselectedContentLeftCol: FC<Props> = ({
  unselectedSections,
  selectedIds,
  setSelectedIds,
}) => {
  return (
    <div
      id="left-column"
      className="flex w-[357px] flex-col gap-4 self-stretch border-r border-border"
    >
      <ScrollableFlexGrow className="flex-1 self-stretch">
        <div className="flex flex-1 flex-col gap-[8px] self-stretch p-[12px]">
          {unselectedSections.length === 0 && (
            <div className="flex flex-1 items-center justify-center self-stretch">
              <Text type="P2" fontWeight="Medium" className="text-gray-6">
                All content selected
              </Text>
            </div>
          )}
          {unselectedSections.map((section) => (
            <div key={section.sectionName}>
              <div className="ml-[8px] flex h-[32px] items-center justify-between">
                <Text type="P2" fontWeight="SemiBold">
                  {section.sectionName}
                </Text>
                <Button
                  variant="ghost"
                  size="sm"
                  className="hover:text-primary/90 h-6 text-primary"
                  onClick={() => {
                    const newSelected = new Set(selectedIds);
                    section.memberships.forEach((membership) => {
                      const libraryItem =
                        membership?.course?.libraryItem ||
                        membership?.trainingResource?.libraryItem ||
                        membership?.skill?.libraryItem;
                      if (libraryItem) {
                        newSelected.add(libraryItem.id);
                      }
                    });
                    setSelectedIds(newSelected);
                  }}
                >
                  <span className="ml-1 text-xs">Add section</span>
                </Button>
              </div>
              <div className="flex flex-col">
                {section.memberships.map((membership) => {
                  const libraryItem =
                    membership?.course?.libraryItem ||
                    membership?.trainingResource?.libraryItem ||
                    membership?.skill?.libraryItem;
                  if (!libraryItem) return null;
                  const isSelected = selectedIds.has(libraryItem.id);
                  return (
                    <div
                      key={membership.id}
                      className={cn(
                        "flex items-center justify-between rounded-md p-[8px] cursor-pointer transition-colors hover:bg-muted",
                      )}
                      onClick={() => {
                        const newSelected = new Set(selectedIds);
                        if (isSelected) {
                          newSelected.delete(libraryItem.id);
                        } else {
                          newSelected.add(libraryItem.id);
                        }
                        setSelectedIds(newSelected);
                      }}
                    >
                      <div className="flex flex-1 items-center gap-2">
                        <ContentAvatar
                          size="32px"
                          coverImage={libraryItem.coverImage}
                          contentType="Course"
                          defaultBackgroundHashKey={libraryItem.id}
                          style={{ borderRadius: 4 }}
                        />
                        <div className="flex h-[32px] max-h-[32px] flex-1 flex-col justify-center">
                          <Text
                            type="P2"
                            ellipsis
                            className="self-stretch"
                            fontWeight="Medium"
                          >
                            {libraryItem.name.en}
                          </Text>
                          <Text type="P2" className="mt-[-2px] text-gray-7">
                            {ContentTypeCopy[libraryItem.type]}
                          </Text>
                        </div>
                        <Plus className="size-4 text-primary" />
                      </div>
                      {isSelected && <X className="size-4 text-primary" />}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </ScrollableFlexGrow>
    </div>
  );
};

export default UnselectedContentLeftCol;
