import { FC } from "react";
import { cn } from "@src/ui/lib/utils";
import { RadioGroupItem } from "@src/ui/radio-group";
import Text from "@ui/text";

type ReassignmentOptionProps = {
  value: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const ReassignmentWholeModuleOption: FC<ReassignmentOptionProps> = ({
  value,
  label,
  checked,
  disabled,
  onClick,
}) => (
  <div
    className={cn(
      "flex w-full items-center gap-2 rounded-lg border p-3 transition-colors",
      checked && !disabled
        ? "border-primary"
        : "border-border hover:border-gray-4",
      disabled && "opacity-50",
      !disabled && "cursor-pointer",
    )}
    onClick={onClick}
  >
    <RadioGroupItem value={value} id={value} />
    <Text type="P2" className="flex-1">
      {label}
    </Text>
  </div>
);

export default ReassignmentWholeModuleOption;
