import {
  PathContentSelectionModal_LibraryItemFragment,
  PathContentSelectionModal_PathContentMembershipFragment,
} from "./index.generated";
import { MembershipsGroupedBySections, MembershipSection } from "./types";

export const transformToGroupedMemberships = (
  libraryItem: PathContentSelectionModal_LibraryItemFragment | null | undefined,
): MembershipsGroupedBySections => {
  if (!libraryItem?.path?.publishedVersion?.pathContentMemberships) {
    return [];
  }

  const memberships: PathContentSelectionModal_PathContentMembershipFragment[] =
    libraryItem.path.publishedVersion.pathContentMemberships;
  const result: MembershipsGroupedBySections = [];
  let currentSection: MembershipSection = { sectionName: "", memberships: [] };
  memberships.forEach((membership, i) => {
    if (membership.sectionBreak) {
      if (i > 0) {
        result.push(currentSection);
      }
      currentSection = {
        sectionName: membership.sectionBreak.title.en,
        memberships: [],
      };
    } else {
      currentSection.memberships.push(membership);
    }
  });
  if (currentSection.memberships.length > 0) {
    result.push(currentSection);
  }
  return result;
};
