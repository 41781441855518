import { FC } from "react";
import Text from "@ui/text";
import { Button } from "@ui/button";
import { MembershipSection } from "../types";
import MembershipContentCards from "./MembershipContentCards";

interface Props {
  selectedSections: MembershipSection[];
  selectedIds: Set<string>;
  setSelectedIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const SelectedContentRightCol: FC<Props> = ({
  selectedSections,
  selectedIds,
  setSelectedIds,
}) => {
  return (
    <div
      id="right-column"
      className="flex flex-1 flex-col gap-4 self-stretch bg-gray-1 p-[12px]"
    >
      {selectedSections.length === 0 && (
        <div className="flex flex-1 flex-col items-center justify-center gap-1 self-stretch">
          <Text type="P2" fontWeight="Medium" className="text-gray-6">
            No content selected
          </Text>
        </div>
      )}
      {selectedSections.map((section) => (
        <div key={section.sectionName}>
          <div className="flex h-[32px] items-center justify-between">
            <Text type="P2" fontWeight="SemiBold">
              {section.sectionName}
            </Text>
            <Button
              variant="ghost"
              size="sm"
              className="hover:text-primary/90 h-6 text-primary"
              onClick={() => {
                const newSelected = new Set(selectedIds);
                section.memberships.forEach((membership) => {
                  const libraryItem =
                    membership?.course?.libraryItem ||
                    membership?.trainingResource?.libraryItem ||
                    membership?.skill?.libraryItem;
                  if (libraryItem) {
                    newSelected.delete(libraryItem.id);
                  }
                });
                setSelectedIds(newSelected);
              }}
            >
              <span className="ml-1 text-xs">Remove section</span>
            </Button>
          </div>
          <MembershipContentCards
            memberships={section.memberships}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        </div>
      ))}
    </div>
  );
};

export default SelectedContentRightCol;
