import AssignLibraryItemModal from "..";
import { useCallback } from "react";
import { useModal } from "@src/hooks/useModal";

const useShowAssignLibraryItemModal = (): ((
  props: React.ComponentProps<typeof AssignLibraryItemModal>,
) => void) => {
  const { showModal } = useModal();
  return useCallback(
    (props) => {
      showModal(<AssignLibraryItemModal {...props} />);
    },
    [showModal],
  );
};

export default useShowAssignLibraryItemModal;
