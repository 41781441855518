import { DateTime } from "luxon";
import { EngagementLevel } from "../../users/activity/ActivityCalendarDay";
import { ComplianceType, LocationGroupType } from "@src/types.generated";
import { onlyFirstLetterCapitalized } from "@src/utils/strings";
import { DeactivationReason } from "@src/types.generated";
import { RoleAutomationFragment } from "@components/roles/role-detail/RoleDetail.generated";
import { ContentType } from "@src/deprecatedDesignSystem/components/ContentAvatar";
import {
  getCourseDetailRoute,
  getFileDetailRoute,
  getPathDetailRoute,
  getPremiumPathRoute,
} from "@components/contentLibrary/utils";
import { ContentFilterType } from "@components/roles/role-detail/RoleAutomationsTable";
import { getRoleString } from "@utils/assignments";
import uniq from "lodash/uniq";
import { Route } from "nextjs-routes";
import { AutomationLocFragment } from "@src/contexts/LocationDetailContext/LocationDetailContext.generated";
import { TrainingSessionFragment } from "@src/components/users/user-details/UserDetailActivity.generated";
import {
  UserDetailComplianceCertificatesTabComplianceAttemptFragment,
  UserDetailComplianceCertificatesTabComplianceRecordFragment,
} from "@src/components/users/assignments/UserDetailComplianceCertificatesTab.generated";
import {
  ASSIGNED_USER_TYPE_COPY,
  getUSStateLabel,
} from "@src/components/sexualHarassmentPrevention/utils";
import { orderBy } from "lodash";
import { format, parseISO } from "date-fns";

export function getAutomationContentName(
  automation: RoleAutomationFragment | AutomationLocFragment,
): string {
  return (
    automation.libraryItem?.orgPremiumContentConfig?.premiumContentConfig
      .catalogPath?.path?.libraryItem?.name.en || automation.libraryItem.name.en
  );
}

export function getAutomationContentId(
  automation: RoleAutomationFragment | AutomationLocFragment,
): string | number {
  return (
    automation.libraryItem?.course?.id ||
    automation.libraryItem?.path?.id ||
    automation.libraryItem?.trainingResource?.id ||
    ""
  );
}

export function getAutomationContentType(automation: {
  libraryItem: RoleAutomationFragment["libraryItem"];
}): ContentType {
  return automation.libraryItem?.course
    ? "Course"
    : automation.libraryItem?.path
      ? "Module"
      : automation.libraryItem?.orgPremiumContentConfig
        ? "PremiumPath"
        : "Resource";
}

export function getAutomationContentUrl(
  automation: RoleAutomationFragment | AutomationLocFragment,
): Route {
  let url: Route;
  if (automation.libraryItem?.course) {
    url = getCourseDetailRoute(automation.libraryItem?.course.id);
  } else if (automation.libraryItem?.path) {
    url = getPathDetailRoute(automation.libraryItem?.path.id);
  } else if (automation.libraryItem?.trainingResource) {
    url = getFileDetailRoute(automation.libraryItem?.trainingResource.id);
  } else if (
    automation.libraryItem?.orgPremiumContentConfig?.premiumContentConfig
      .catalogPath?.id
  ) {
    url = getPremiumPathRoute(
      automation.libraryItem?.orgPremiumContentConfig.premiumContentConfig
        .catalogPath?.id,
    );
  } else {
    throw Error("Invalid automation");
  }
  return url;
}

export function filterAutomationsByContentType(
  automations: RoleAutomationFragment[] | AutomationLocFragment[],
  contentType: ContentFilterType,
): RoleAutomationFragment[] {
  let filtered = automations;
  if (contentType === ContentFilterType.COURSE)
    filtered = automations.filter(
      (automation) => automation.libraryItem?.course,
    );
  if (contentType === ContentFilterType.MODULE)
    filtered = automations.filter((automation) => automation.libraryItem?.path);
  if (contentType === ContentFilterType.FILE)
    filtered = automations.filter(
      (automation) => automation.libraryItem?.trainingResource,
    );
  return filtered;
}

export function getRoleTypes(automations: RoleAutomationFragment[]): string[] {
  return uniq(
    ["All"].concat(
      automations.map((automation) => getRoleString(automation.userSelection)),
    ),
  );
}

export function filterAutomationsByRoleType(
  automations: RoleAutomationFragment[] | AutomationLocFragment[],
  roleType: string,
): RoleAutomationFragment[] {
  if (roleType === "All") {
    return automations;
  }
  return automations.filter(
    (automation) => getRoleString(automation.userSelection) === roleType,
  );
}

export function getDeactivatedByCopy(e: {
  deactivatedReason?: DeactivationReason | null;
  deactivatedBy?: { name: string } | null;
}): string {
  if (e.deactivatedReason === DeactivationReason.Hris) {
    return "by HRIS integration";
  } else if (e.deactivatedReason === DeactivationReason.SwitchedOrgs) {
    return "by switching companies";
  } else if (e.deactivatedReason === DeactivationReason.Manual) {
    return "manually" + (e.deactivatedBy ? " by " + e.deactivatedBy?.name : "");
  } else if (e.deactivatedReason === DeactivationReason.DeactivatedOrg) {
    return "when the company was deactivated";
  } else {
    return "";
  }
}

type DidEarnCertificateAssignment = {
  currentCourseInstance?:
    | {
        certificateUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

export const getCourseCertificateUrl = (
  assignment: DidEarnCertificateAssignment,
): string | null => {
  const ci = assignment.currentCourseInstance;
  return ci?.certificateUrl || null;
};

export const formatComplianceTypeNames = (
  complianceType: ComplianceType,
): string => {
  const capitalizedWords = complianceType.split("_").map((word) => {
    return onlyFirstLetterCapitalized(word);
  });

  return capitalizedWords.join(" ");
};

export const getPersonDetailRoute = (userId: number): Route => {
  return {
    pathname: "/users/[id]",
    query: {
      id: userId.toString(),
    },
  };
};

export const getRoleDetailRoute = (roleId: number): Route => {
  return {
    pathname: "/roles/[id]",
    query: {
      id: roleId.toString(),
    },
  };
};

export const getLocationDetailRoute = (locationId: number): Route => {
  return {
    pathname: "/locations/[id]",
    query: {
      id: locationId.toString(),
    },
  };
};

export const getLocationGroupDetailRoute = (
  locationGroupId: string,
  locationGroupType: LocationGroupType,
): Route => {
  if (locationGroupType === LocationGroupType.Region) {
    return {
      pathname: "/locations/regions/[id]",
      query: {
        id: locationGroupId,
      },
    };
  } else if (locationGroupType === LocationGroupType.Brand) {
    return {
      pathname: "/locations/brands/[id]",
      query: {
        id: locationGroupId,
      },
    };
  } else {
    return {
      pathname: "/locations/franchises/[id]",
      query: {
        id: locationGroupId,
      },
    };
  }
};

export const getRoleGroupDetailRoute = (roleGroupId: string): Route => {
  return {
    pathname: "/roles/departments/[id]",
    query: {
      id: roleGroupId,
    },
  };
};

export const getTagRoute = (tagId: string): Route => {
  return {
    pathname: "/library/modules",
    query: {
      tagIds: tagId,
    },
  };
};

export const getEngagementLevel = (
  sessions: TrainingSessionFragment[],
): EngagementLevel => {
  const seconds = sessions.reduce((accum, session) => {
    return (
      accum +
      DateTime.fromISO(session.endTime).diff(
        DateTime.fromISO(session.startTime),
        "seconds",
      ).seconds
    );
  }, 0);

  if (seconds === 0) {
    return 0;
  } else if (seconds < 60) {
    return 1;
  } else if (seconds < 120) {
    return 2;
  } else if (seconds < 180) {
    return 3;
  } else {
    return 4;
  }
};

export const filterEnabledAutomations = (
  automations: RoleAutomationFragment[] | AutomationLocFragment[],
): RoleAutomationFragment[] | AutomationLocFragment[] => {
  return automations.filter((automation) => automation.enabled);
};

export const getComplianceRecordCertificateUrl = (
  x: UserDetailComplianceCertificatesTabComplianceRecordFragment,
): string | null | undefined => {
  const attempt = getMostRecentComplianceAttempt(x.attempts);
  return (
    attempt?.pathInstance?.certificateUrl ||
    attempt?.courseInstance?.certificateUrl
  );
};

export const getComplianceRecordAssignedUserType = (
  x: UserDetailComplianceCertificatesTabComplianceRecordFragment,
): string | undefined | null => {
  const attempt = getMostRecentComplianceAttempt(x.attempts);
  if (!attempt || !attempt.assignedUserType) return null;
  return ASSIGNED_USER_TYPE_COPY[attempt.assignedUserType];
};

export const getComplianceRecordStartDate = (
  x: UserDetailComplianceCertificatesTabComplianceRecordFragment,
): string | null => {
  return x.user.hrisEmployee?.startDate
    ? format(parseISO(x.user.hrisEmployee.startDate), "M/d/yyyy")
    : null;
};

export const getMostRecentComplianceAttempt = (
  x: UserDetailComplianceCertificatesTabComplianceAttemptFragment[],
): UserDetailComplianceCertificatesTabComplianceAttemptFragment | undefined => {
  return orderBy(
    x.filter((c) => c.compliantUntil),
    "createdAt",
    "desc",
  )[0];
};

export const getComplianceRecordUsState = (
  x: UserDetailComplianceCertificatesTabComplianceRecordFragment,
): string => {
  const attempt = getMostRecentComplianceAttempt(x.attempts);
  return getUSStateLabel(attempt?.usState);
};
