import { FC, useCallback, useMemo, useState } from "react";
import { useModal } from "@src/hooks/useModal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ContentAvatar, {
  LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { launchConfetti } from "@utils/confetti";
import {
  AssignLibraryItemsInput,
  LibraryItemType,
  UserSelectionInput,
} from "@src/types.generated";
import { useToast } from "@src/hooks/useToast";

import { ASSIGNMENT_REFETCH_QUERIES } from "../constants";
import useUserSelectionInputState from "../hooks/useUserSelectionInputState";
import CreditsChargeWhenStartedBanner from "../CreditsChargeWhenStartedBanner";
import { useGetUsersForUserSelectionQuery } from "../hooks/useGetUsersForOneOffAssignment.generated";
import { Button } from "@src/ui/button";

import UserSelectionPreviewButton from "./components/UserSelectionPreviewButton";
import ReassignmentSection from "./components/ReassignmentSection";
import SelectUsersModal from "../SelectUsersModal";
import useAssignLibraryItems from "@src/hooks/useAssignLibraryItems";
import { gql } from "@apollo/client";
import { useAssignLibraryItemModal_LibraryItemQuery } from "./index.generated";
import AssignmentSummary from "./components/AssignmentSummary";
import { useAssignmentSummaryData } from "./hooks/useAssignmentSummaryData";
import { isUserSelectionInputEmpty } from "@src/utils/assignments";

type Props = {
  creditCostPerAssignment?: number;
  userSelectionInput?: UserSelectionInput;
  libraryItemId: string;
  reassignOnly?: boolean;
};

const AssignLibraryItemModal: FC<Props> = (props) => {
  const { userSelectionInput, setUserSelectionInput } =
    useUserSelectionInputState({
      input: props.userSelectionInput,
      allowUserTypes: false,
    });

  const { data: userSelectionData } = useGetUsersForUserSelectionQuery({
    variables: { input: userSelectionInput },
  });

  const selectedUserIds: Set<number> = useMemo(() => {
    return new Set(
      userSelectionData?.GetUsersForUserSelection.map((u) => u.id),
    );
  }, [userSelectionData]);

  const { showModal, closeModal } = useModal();
  const { addErrorToast } = useToast();
  const { data: libraryItemData } = useAssignLibraryItemModal_LibraryItemQuery({
    variables: { id: props.libraryItemId },
  });
  const { addToast } = useToast();
  const [reassignWholePath, setReassignWholePath] = useState(true);
  const [
    selectedPathContentLibraryItemIds,
    setSelectedPathContentLibraryItemIds,
  ] = useState<string[]>([]);
  const [shouldReassign, setShouldReassign] = useState(!!props.reassignOnly);
  const isModule = !!libraryItemData?.LibraryItem?.path?.id;
  const isModuleOrCourse = !!(
    isModule || libraryItemData?.LibraryItem?.course?.id
  );

  const mutationInput: AssignLibraryItemsInput = useMemo(() => {
    const input: AssignLibraryItemsInput = {
      libraryItemIds: [props.libraryItemId],
      userSelection: userSelectionInput,
      reassign: shouldReassign,
      reassignLibraryItemIdsInPath:
        isModule && shouldReassign && !reassignWholePath
          ? selectedPathContentLibraryItemIds
          : undefined,
    };
    return input;
  }, [
    shouldReassign,
    reassignWholePath,
    selectedPathContentLibraryItemIds,
    props.libraryItemId,
    userSelectionInput,
    isModule,
  ]);
  const [
    assignLibraryItemsMutation,
    { loading: assignLibraryItemsMutationLoading },
  ] = useAssignLibraryItems({
    onCompleted: (result) => {
      if (result.assignLibraryItems.success) {
        closeModal();
        launchConfetti();
        addToast({
          message: "Assignment successful",
        });
      } else {
        addErrorToast({ ...result, callsite: "one_off_assignment_modal" });
      }
    },
    refetchQueries: ASSIGNMENT_REFETCH_QUERIES,
    variables: {
      input: mutationInput,
    },
  });

  const modalHeader = useMemo(() => {
    const element = (
      <Text type="P2" fontWeight="SemiBold">
        {props.reassignOnly ? "Reassign" : "Assign"}
      </Text>
    );
    return <TitleHeader element={element} onCancelClick={closeModal} />;
  }, [closeModal, props.reassignOnly]);

  const assign = useCallback(() => {
    assignLibraryItemsMutation();
  }, [assignLibraryItemsMutation]);

  const modalFooter = useMemo(() => {
    const primaryButton = (
      <Button
        data-testid="assign-button"
        onClick={assign}
        disabled={
          isUserSelectionInputEmpty(userSelectionInput) ||
          assignLibraryItemsMutationLoading
        }
      >
        {props.reassignOnly ? "Reassign" : "Assign"}
      </Button>
    );
    return (
      <Footer
        primaryButton={primaryButton}
        cancelTitle="Cancel"
        onCancelClick={closeModal}
      />
    );
  }, [
    assign,
    userSelectionInput,
    assignLibraryItemsMutationLoading,
    closeModal,
    props.reassignOnly,
  ]);

  const handleOnSelect = useCallback(
    (newInput: UserSelectionInput) => {
      setUserSelectionInput(newInput);
    },
    [setUserSelectionInput],
  );

  const showSelectUsersModal = useCallback(() => {
    showModal(
      <SelectUsersModal input={userSelectionInput} onSelect={handleOnSelect} />,
    );
  }, [handleOnSelect, showModal, userSelectionInput]);

  const contentType =
    LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE[
      libraryItemData?.LibraryItem?.type || LibraryItemType.Path
    ];

  const assignmentSummaryData = useAssignmentSummaryData(
    userSelectionInput,
    props.libraryItemId,
  );
  return (
    <Modal
      width={480}
      header={modalHeader}
      footer={modalFooter}
      dataTestId={"assignment-modal"}
    >
      <AutoLayout
        padding={24}
        minHeight={240}
        direction="vertical"
        spaceBetweenItems={12}
      >
        <AutoLayout spaceBetweenItems={12} alignmentVertical="center">
          <ContentAvatar
            style={{ borderRadius: 8 }}
            contentType={contentType}
            coverImage={libraryItemData?.LibraryItem?.coverImage}
            media={libraryItemData?.LibraryItem?.trainingResource?.media}
            defaultBackgroundHashKey={props.libraryItemId}
          />
          <AutoLayout direction="vertical" spaceBetweenItems={2}>
            <Text type="P1" fontWeight="SemiBold">
              {libraryItemData?.LibraryItem?.name?.en || "Untitled"}
            </Text>
            <Text type="P3" fontWeight="Medium" color={deprecatedTones.gray7}>
              {contentType}
            </Text>
          </AutoLayout>
        </AutoLayout>
        <UserSelectionPreviewButton
          onClick={showSelectUsersModal}
          totalSelectedUsers={selectedUserIds.size}
          input={userSelectionInput}
          reassignOnly={props.reassignOnly}
        />
        <AutoLayout
          spaceBetweenItems={18}
          direction="vertical"
          alignSelf="stretch"
        >
          {props.creditCostPerAssignment &&
            props.creditCostPerAssignment > 0 && (
              <CreditsChargeWhenStartedBanner
                creditCostPerAssignment={props.creditCostPerAssignment}
              />
            )}
        </AutoLayout>
        {isModuleOrCourse &&
          assignmentSummaryData.userIdsAlreadyAssigned.size > 0 && (
            <ReassignmentSection
              libraryItemId={props.libraryItemId}
              userIdsToReassign={assignmentSummaryData.userIdsAlreadyAssigned}
              shouldReassign={shouldReassign}
              setShouldReassign={setShouldReassign}
              reassignOnly={props.reassignOnly}
              reassignWholePath={reassignWholePath}
              setReassignWholePath={setReassignWholePath}
              selectedPathContentLibraryItemIds={
                selectedPathContentLibraryItemIds
              }
              setSelectedPathContentLibraryItemIds={
                setSelectedPathContentLibraryItemIds
              }
              isModule={isModule}
            />
          )}

        {!isUserSelectionInputEmpty(userSelectionInput) && (
          <AssignmentSummary
            summaryData={assignmentSummaryData}
            shouldRestart={shouldReassign}
            partialModuleReassignment={
              !reassignWholePath &&
              isModule &&
              !!selectedPathContentLibraryItemIds.length
            }
          />
        )}
      </AutoLayout>
    </Modal>
  );
};

export default AssignLibraryItemModal;

gql`
  query AssignLibraryItemModal_LibraryItem($id: UUID!) {
    LibraryItem(id: $id) {
      id
      path {
        id
      }
      course {
        id
      }
      coverImage {
        ...CoverImage
      }
      name {
        en
      }
      type
      trainingResource {
        media {
          ...Media
        }
      }
    }
  }
  query AssignLibraryItemModal_ExistingAssignments($input: AssignmentsInput!) {
    Assignments(input: $input) {
      objects {
        id
        user {
          id
        }
        status
      }
    }
  }
  fragment AssignLibraryItemModalModuleMembersihps_PathContentMembership on PathContentMembership {
    id
    course {
      id
      libraryItem {
        id
        name {
          en
        }
        coverImage {
          ...CoverImage
        }
      }
    }
    sectionBreak {
      id
      title {
        en
      }
    }
  }

  fragment AssignLibraryItemModalModuleMembersihps_LibraryItem on LibraryItem {
    id
    path {
      id
      publishedVersion {
        pathContentMemberships {
          ...AssignLibraryItemModalModuleMembersihps_PathContentMembership
        }
      }
    }
  }

  query AssignLibraryItemModalModuleMembersihps_LibraryItem($id: UUID!) {
    LibraryItem(id: $id) {
      ...AssignLibraryItemModalModuleMembersihps_LibraryItem
    }
  }
`;
