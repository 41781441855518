import { useMemo } from "react";
import { gql } from "@apollo/client";
import { AccessType, UserSelectionInput } from "@src/types.generated";
import { useGetUsersForUserSelectionQuery } from "../../hooks/useGetUsersForOneOffAssignment.generated";
import { useUseAssignmentSummaryData_AssignmentsQuery } from "./useAssignmentSummaryData.generated";

export type AssignmentSummaryData = {
  userIdsToAssignForFirstTime: Set<number>;
  userIdsAlreadyAssigned: Set<number>;
  userIdsStartedOrCompleted: Set<number>;
  userIdsNotStarted: Set<number>;
  userIdsInProgress: Set<number>;
  userIdsCompleted: Set<number>;
  userIdsNotCompleted: Set<number>;
};

export const useAssignmentSummaryData = (
  userSelection: UserSelectionInput,
  libraryItemId: string,
): AssignmentSummaryData => {
  const { data: userIdsData } = useGetUsersForUserSelectionQuery({
    variables: { input: userSelection },
    skip: !userSelection,
  });

  const userSelectionUserIds: Set<number> = useMemo(
    () => new Set(userIdsData?.GetUsersForUserSelection.map((u) => u.id)),
    [userIdsData],
  );

  const { data: alreadyAssignedData } =
    useUseAssignmentSummaryData_AssignmentsQuery({
      variables: {
        input: {
          filters: {
            libraryItemIds: { value: [libraryItemId] },
            userIds: { value: Array.from(userSelectionUserIds) },
            accessTypes: { value: [AccessType.Assignment] },
          },
        },
      },
      skip: !libraryItemId || userSelectionUserIds.size === 0,
    });
  const { data: alreadyAssignedAndStartedOrCompletedData } =
    useUseAssignmentSummaryData_AssignmentsQuery({
      variables: {
        input: {
          filters: {
            libraryItemIds: { value: [libraryItemId] },
            userIds: { value: Array.from(userSelectionUserIds) },
            accessTypes: { value: [AccessType.Assignment] },
            currentInstanceStarted: { value: true },
          },
        },
      },
      skip: !libraryItemId || userSelectionUserIds.size === 0,
    });
  const { data: alreadyAssignedAndCompletedData } =
    useUseAssignmentSummaryData_AssignmentsQuery({
      variables: {
        input: {
          filters: {
            libraryItemIds: { value: [libraryItemId] },
            userIds: { value: Array.from(userSelectionUserIds) },
            accessTypes: { value: [AccessType.Assignment] },
            statuses: { value: ["complete"] },
          },
        },
      },
      skip: !libraryItemId || userSelectionUserIds.size === 0,
    });
  const userIdsAlreadyAssigned: Set<number> = useMemo(() => {
    const ids = new Set(
      alreadyAssignedData?.Assignments?.objects.map((a) => a.user.id),
    );
    return new Set([...userSelectionUserIds].filter((id) => ids.has(id)));
  }, [userSelectionUserIds, alreadyAssignedData]);
  const userIdsStartedOrCompleted: Set<number> = useMemo(() => {
    const ids = new Set(
      alreadyAssignedAndStartedOrCompletedData?.Assignments?.objects.map(
        (a) => a.user.id,
      ),
    );
    return new Set([...userSelectionUserIds].filter((id) => ids.has(id)));
  }, [userSelectionUserIds, alreadyAssignedAndStartedOrCompletedData]);
  const userIdsToAssignForFirstTime: Set<number> = useMemo(
    () =>
      new Set(
        [...userSelectionUserIds].filter(
          (id) => !userIdsAlreadyAssigned.has(id),
        ),
      ),
    [userSelectionUserIds, userIdsAlreadyAssigned],
  );
  const userIdsCompleted: Set<number> = useMemo(() => {
    const ids = new Set(
      alreadyAssignedAndCompletedData?.Assignments?.objects.map(
        (a) => a.user.id,
      ),
    );
    return new Set([...userSelectionUserIds].filter((id) => ids.has(id)));
  }, [userSelectionUserIds, alreadyAssignedAndCompletedData]);
  const userIdsNotCompleted: Set<number> = useMemo(
    () =>
      new Set(
        [...userSelectionUserIds].filter(
          (id) => userIdsAlreadyAssigned.has(id) && !userIdsCompleted.has(id),
        ),
      ),
    [userSelectionUserIds, userIdsAlreadyAssigned, userIdsCompleted],
  );
  const userIdsNotStarted: Set<number> = useMemo(
    () =>
      new Set(
        [...userSelectionUserIds].filter(
          (id) =>
            userIdsAlreadyAssigned.has(id) &&
            !userIdsStartedOrCompleted.has(id),
        ),
      ),
    [userSelectionUserIds, userIdsAlreadyAssigned, userIdsStartedOrCompleted],
  );
  const userIdsInProgress: Set<number> = useMemo(
    () =>
      new Set(
        [...userSelectionUserIds].filter(
          (id) =>
            userIdsStartedOrCompleted.has(id) && !userIdsCompleted.has(id),
        ),
      ),
    [userSelectionUserIds, userIdsStartedOrCompleted, userIdsCompleted],
  );
  return useMemo(() => {
    return {
      userIdsToAssignForFirstTime,
      userIdsAlreadyAssigned,
      userIdsStartedOrCompleted,
      userIdsCompleted,
      userIdsNotStarted,
      userIdsInProgress,
      userIdsNotCompleted,
    };
  }, [
    userIdsToAssignForFirstTime,
    userIdsAlreadyAssigned,
    userIdsStartedOrCompleted,
    userIdsCompleted,
    userIdsNotStarted,
    userIdsInProgress,
    userIdsNotCompleted,
  ]);
};

gql`
  query useAssignmentSummaryData_Assignments($input: AssignmentsInput!) {
    Assignments(input: $input) {
      objects {
        id
        status
        user {
          id
        }
      }
    }
  }
`;
