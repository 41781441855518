import { FC } from "react";
import { cn } from "@src/ui/lib/utils";
import { RadioGroupItem } from "@src/ui/radio-group";
import Text from "@ui/text";
import { Button } from "@src/ui/button";
import { gql } from "@apollo/client";
import MembershipContentCards from "../../pathContentSelectionModal/components/MembershipContentCards";
import { useReassignmentSelectPathContent_LibraryItemQuery } from "./ReassignmentSelectContentOption.generated";
import Spacer from "@src/components/ui/Spacer";

type ReassignmentSelectCoursesOptionProps = {
  value: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
  selectedPathContentLibraryItemIds: string[];
  libraryItemId: string;
};

const ReassignmentSelectContentOption: FC<
  ReassignmentSelectCoursesOptionProps
> = ({
  value,
  label,
  checked,
  disabled,
  onClick,
  selectedPathContentLibraryItemIds,
  libraryItemId,
}) => {
  const { data } = useReassignmentSelectPathContent_LibraryItemQuery({
    variables: { id: libraryItemId },
  });
  const publishedPathContentMemberships =
    data?.LibraryItem?.path?.publishedVersion?.pathContentMemberships;
  return (
    <div
      className={cn(
        "flex flex-col self-stretch items-center rounded-lg border overflow-hidden",
        checked && !disabled
          ? "border-primary"
          : "border-border hover:border-gray-4",
        disabled && "opacity-50",
        !disabled && "cursor-pointer",
      )}
      onClick={onClick}
    >
      <div className="flex items-center self-stretch p-3">
        <div className="flex flex-1 gap-2">
          <RadioGroupItem value={value} id={value} />
          <Text type="P2">{label}</Text>
          {selectedPathContentLibraryItemIds.length > 0 && (
            <Text type="P2" className="text-gray-7">
              {selectedPathContentLibraryItemIds.length} selected
            </Text>
          )}
        </div>
        {selectedPathContentLibraryItemIds.length > 0 && (
          <Button variant="default" className="h-[28px] px-[8px]">
            Edit
          </Button>
        )}
      </div>
      {selectedPathContentLibraryItemIds.length > 0 &&
        publishedPathContentMemberships && (
          <div className="flex max-h-[130px] flex-col self-stretch overflow-y-scroll bg-gray-1 p-[12px]">
            <div className="flex gap-2 self-stretch">
              <MembershipContentCards
                memberships={publishedPathContentMemberships}
                selectedIds={new Set(selectedPathContentLibraryItemIds)}
              />
            </div>
            <Spacer size={0} />
          </div>
        )}
    </div>
  );
};

export default ReassignmentSelectContentOption;

gql`
  fragment ReassignmentSelectPathContent_LibraryItem on LibraryItem {
    id
    path {
      id
      publishedVersion {
        pathContentMemberships {
          ...PathContentSelectionModal_PathContentMembership
        }
      }
    }
  }

  query ReassignmentSelectPathContent_LibraryItem($id: UUID!) {
    LibraryItem(id: $id) {
      ...ReassignmentSelectPathContent_LibraryItem
    }
  }
`;
