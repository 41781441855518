import { FC, useMemo } from "react";
import { pluralize } from "@src/utils/strings";
import ButtonWithPreview from "@src/deprecatedDesignSystem/components/ButtonWithPreview";
import UserSelectionPreview from "../../UserSelectionPreview";
import useUserSelectionPreviewState from "../../hooks/useUserSelectionPreviewState";
import { UserSelectionInput } from "@src/types.generated";

type UserSelectionPreviewButtonProps = {
  input: UserSelectionInput;
  totalSelectedUsers: number;
  onClick: () => void;
  reassignOnly?: boolean;
};

const UserSelectionPreviewButton: FC<UserSelectionPreviewButtonProps> = ({
  input,
  totalSelectedUsers,
  onClick,
  reassignOnly,
}) => {
  const { hasSelection } = useUserSelectionPreviewState({ input });
  const label = useMemo(() => {
    return totalSelectedUsers > 0
      ? `${reassignOnly ? "Reassign to" : "Assign to"} ${totalSelectedUsers} ${pluralize(
          "user",
          totalSelectedUsers,
        )}`
      : undefined;
  }, [totalSelectedUsers, reassignOnly]);

  return (
    <ButtonWithPreview
      label={label}
      leftIcon="user-circle"
      placeholder="Select users"
      onClick={onClick}
    >
      {hasSelection && <UserSelectionPreview input={input} />}
    </ButtonWithPreview>
  );
};

export default UserSelectionPreviewButton;
