import { FC, useCallback } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { Switch } from "@src/ui/switch";
import { pluralize } from "@src/utils/strings";
import ReassignmentWholeModuleOption from "./ReassignmentWholeModuleOption";
import ReassignmentSelectContentOption from "./ReassignmentSelectContentOption";
import { RadioGroup } from "@src/ui/radio-group";
import { useModal } from "@src/hooks/useModal";
import PathContentSelectionModal from "../../pathContentSelectionModal";

type ReassignmentSectionProps = {
  libraryItemId: string;
  userIdsToReassign: Set<number>;
  shouldReassign: boolean;
  setShouldReassign: (value: boolean) => void;
  reassignWholePath: boolean;
  setReassignWholePath: (value: boolean) => void;
  selectedPathContentLibraryItemIds: string[];
  setSelectedPathContentLibraryItemIds: (value: string[]) => void;
  isModule: boolean;
  reassignOnly?: boolean;
};

const ReassignmentSection: FC<ReassignmentSectionProps> = ({
  libraryItemId,
  userIdsToReassign,
  shouldReassign,
  setShouldReassign,
  reassignOnly,
  reassignWholePath,
  setReassignWholePath,
  selectedPathContentLibraryItemIds,
  setSelectedPathContentLibraryItemIds,
  isModule,
}) => {
  const { showModal } = useModal();
  const handleValueChange = useCallback(
    (value: string) => {
      setReassignWholePath(value === "entire");
      if (value === "select") {
        setSelectedPathContentLibraryItemIds([]);
      }
    },
    [setReassignWholePath, setSelectedPathContentLibraryItemIds],
  );
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={16}
      className="self-stretch"
    >
      {!reassignOnly && (
        <AutoLayout
          direction="vertical"
          spaceBetweenItems={8}
          className="self-stretch"
        >
          <AutoLayout
            spacingMode="space-between"
            alignmentVertical="center"
            className="self-stretch"
          >
            <Text type="P2" fontWeight="Medium">
              Reassign to {userIdsToReassign.size}{" "}
              {pluralize("user", userIdsToReassign.size)}
            </Text>
            <Switch
              checked={shouldReassign}
              onCheckedChange={setShouldReassign}
            />
          </AutoLayout>
        </AutoLayout>
      )}

      {isModule && (
        <RadioGroup
          className="self-stretch"
          value={reassignWholePath ? "entire" : "select"}
          disabled={!shouldReassign}
          onValueChange={handleValueChange}
        >
          <AutoLayout
            direction="vertical"
            spaceBetweenItems={8}
            className="self-stretch"
          >
            <ReassignmentWholeModuleOption
              value="entire"
              label="Reassign entire module including check-ins"
              checked={reassignWholePath}
              disabled={!shouldReassign}
              onClick={() => {
                setReassignWholePath(true);
                setSelectedPathContentLibraryItemIds([]);
              }}
            />
            <ReassignmentSelectContentOption
              libraryItemId={libraryItemId}
              value="select"
              label="Select content to reassign"
              checked={!reassignWholePath}
              disabled={!shouldReassign}
              selectedPathContentLibraryItemIds={
                selectedPathContentLibraryItemIds
              }
              onClick={() => {
                setReassignWholePath(false);
                showModal(
                  <PathContentSelectionModal
                    pathLibraryItemId={libraryItemId}
                    initialSelectedContentLibraryItemIds={
                      selectedPathContentLibraryItemIds
                    }
                    onSubmit={(librrayItemids) => {
                      setSelectedPathContentLibraryItemIds(librrayItemids);
                      if (librrayItemids.length === 0) {
                        setReassignWholePath(true);
                      }
                    }}
                  />,
                );
              }}
            />
          </AutoLayout>
        </RadioGroup>
      )}
    </AutoLayout>
  );
};

export default ReassignmentSection;
